@import "../../Styles/fonts.scss";
@import "../../Styles/color.scss";

.fa-trash {
  color: $secondary-color;
  cursor: pointer;
  top: 0.25rem;
  right: 0;
  position: absolute;
}
