@font-face {
  font-family: "Helvetica";
  src: url("../Resources/Fonts/Helvetica.ttf");
}
@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../Resources/Fonts/HelveticaNeue-Light.ttf");
}
@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../Resources/Fonts/HelveticaNeue-Bold.ttf");
}
@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../Resources/Fonts/HelveticaNeue-Medium.ttf");
}
@font-face {
  font-family: "HelveticaNeue-Thin";
  src: url("../Resources/Fonts/HelveticaNeue-Thin.ttf");
}

.FontThin {
  font-family: "HelveticaNeue-Thin", Arial, sans-serif;
}
.FontLight {
  font-family: "HelveticaNeue-Light", Arial, sans-serif;
}
.FontRegular {
  font-family: Helvetica, Arial, sans-serif;
}
.FontMedium {
  font-family: "HelveticaNeue-Medium", "Arial Bold", sans-serif;
}
.FontBold {
  font-family: "HelveticaNeue-Bold", "Arial Bold", sans-serif;
}
.FontBlack {
  font-family: "HelveticaNeue-Bold", "Arial Bold", sans-serif;
}

.FontTitleLight {
  font-family: "HelveticaNeue-Light", Arial, sans-serif;
}
.FontTitleRegular {
  font-family: Helvetica, Arial, sans-serif;
}
.FontTitleMedium {
  font-family: "HelveticaNeue-Medium", "Arial Bold", sans-serif;
}
.FontTitleBold {
  font-family: "HelveticaNeue-Bold", "Arial Bold", sans-serif;
}
.FontIcon {
  font-family: "Font Awesome 5 Free";
}
.FontBrand {
  font-family: "Font Awesome 5 Brands";
}
$font-size-ultra-small: 0.5625em;
$font-size-extra-small: 0.625em;
$font-size-small: 0.8em;
$font-size-medium: 1em;
$font-size-large: 1.5em;
$font-size-larger: 2em;
$font-size-super-large: 3em;
